import {
  fetchItemList,
  issueItem,
  IssueItemRequest,
  updateIssuedItem,
  EditItemRequest,
  AddOtherChargesRequest,
  addOtherCharges,
  AddPaymentRequest,
  addPayment,
  deletePayment,
  SlipPDFRequest,
  getSlipPDF,
  AccountRequest,
  updateBill,
  BillPDFRequest,
  getTodayBill,
  AddStockItemRequest,
  addStockItem,
  fetchSelectItemOptions,
  getPendingItems,
  deleteStockItem,
  deleteChallan,
} from '../../../services'
import { SelectOptions } from '../../../services/types'

export const ActionTypes = {
  FETCH_ITEMS: 'FETCH_ITEMS',
  FETCH_ITEMS_OPTIONS: 'FETCH_ITEMS_OPTIONS',
  UPDATE_SELECT_OPTIONS: 'UPDATE_SELECT_OPTIONS',
  ISSUE_ITEMS: 'ISSUE_ITEMS',
  CLOSE_ALERT: 'CLOSE_ALERT',
  UPDATE_ISSUED_ITEM: 'UPDATE_ISSUED_ITEM',
  ADD_PAYMENT: 'ADD_PAYMENT',
  DELETE_PAYMENT: 'DELETE_PAYMENT',
  ADD_OTHER_CHARGES: 'ADD_OTHER_CHARGES',
  EDIT_BILL: 'EDIT_BILL',
  GET_SLIP_PDF: 'GET_SLIP_PDF',
  GET_ENTRIES_BY_DATE: 'GET_ENTRIES_BY_DATE',
  GET_TODAY_BILL_PDF: 'GET_TODAY_BILL_PDF',
  ADD_STOCK_ITEM: 'ADD_STOCK_ITEM',
  UPDATE_SEARCH_ITEM_RESULTS: 'UPDATE_SEARCH_ITEM_RESULTS',
  DELETE_STOCK_ITEM: 'DELETE_STOCK_ITEM',
  DELETE_CHALLAN: 'DELETE_CHALLAN',
  FTECH_PENDING_ITEMS: 'FTECH_PENDING_ITEMS',
}

export const updateSelectOptions = (options) => ({
  type: ActionTypes.UPDATE_SELECT_OPTIONS,
  res: options,
})

export const prepareSelectOptions = (data) => {
  const selectOptions =
    data.length > 0
      ? data.map((item): SelectOptions => {
          return {
            label: item.itemName,
            id: item.id,
            rate: item.rate,
            availableQuantity: item.availableQuantity,
            category: item.category.name,
            isArea: item.isArea,
          }
        })
      : []
  return selectOptions
}

export const syncSelectOptionsAction =
  (items = []) =>
  (dispatch, getState) => {
    const { data } = getState().manageOptionsItems
    let selectOptions = prepareSelectOptions(data)
    if (items?.length) {
      const itemIds = items.map((item) => item.item.id)
      selectOptions = selectOptions.reduce((accumulator, currentValue) => {
        if (itemIds.includes(currentValue.id)) {
          return accumulator
        } else {
          return [...accumulator, currentValue]
        }
      }, [])
    }
    dispatch({
      type: ActionTypes.UPDATE_SELECT_OPTIONS,
      res: selectOptions,
    })
  }

export const fetchItemListAction = (categoryId?: number) => ({
  type: ActionTypes.FETCH_ITEMS,
  promise: fetchItemList(categoryId),
})

/**
 * This action to populate the select dropdown while issueing the item
 * @param customer_id
 * @returns
 */

export const fetchtItemOptionsAction = (customer_id, agreementId?: number, fromSite?: number) => ({
  type: ActionTypes.FETCH_ITEMS_OPTIONS,
  promise: fetchSelectItemOptions(customer_id, agreementId, fromSite),
})

export const closeAlertAction = () => ({
  type: ActionTypes.CLOSE_ALERT,
})

export const issueItemRequestAction = () => {
  return {
    type: `${ActionTypes.ISSUE_ITEMS}_RESET`,
  }
}

export const issueItemAction = (request: IssueItemRequest) => {
  return {
    type: ActionTypes.ISSUE_ITEMS,
    promise: issueItem(request),
  }
}

export const updateIssuedItemAction = (request: EditItemRequest) => {
  return {
    type: ActionTypes.UPDATE_ISSUED_ITEM,
    promise: updateIssuedItem(request),
  }
}

export const deleteChallanAction = (id: number) => {
  return {
    type: ActionTypes.DELETE_CHALLAN,
    promise: deleteChallan(id),
  }
}

export const resetDeleteChallanAction = () => {
  return {
    type: `${ActionTypes.DELETE_CHALLAN}_RESET`,
  }
}

export const addPaymentAction = (request: AddPaymentRequest) => {
  return {
    type: ActionTypes.ADD_PAYMENT,
    promise: addPayment(request),
  }
}

export const deletePaymentAction = (id: number) => {
  return {
    type: ActionTypes.DELETE_PAYMENT,
    promise: deletePayment(id),
  }
}

export const updateIssuedItemResetAction = () => ({
  type: `${ActionTypes.UPDATE_ISSUED_ITEM}_RESET`,
})

export const addPaymentResetAction = () => ({
  type: `${ActionTypes.ADD_PAYMENT}_RESET`,
})

export const deletePaymentResetAction = () => ({
  type: `${ActionTypes.DELETE_PAYMENT}_RESET`,
})

/*===================Add Other Charges Starts==============================*/

export const addOtherChargesAction = (request: AddOtherChargesRequest) => {
  return {
    type: ActionTypes.ADD_OTHER_CHARGES,
    promise: addOtherCharges(request),
  }
}

export const addOtherChargesResetAction = () => ({
  type: `${ActionTypes.ADD_OTHER_CHARGES}_RESET`,
})

/*===================Add Other Charges Starts==============================*/

export const updateBillAction = (request: AccountRequest) => {
  return {
    type: ActionTypes.EDIT_BILL,
    promise: updateBill(request),
  }
}

export const editBillResetAction = () => ({
  type: `${ActionTypes.EDIT_BILL}_RESET`,
})

/*===================Slip pdf==============================*/

export const getSlipPDFAction = (request: SlipPDFRequest) => {
  return {
    type: ActionTypes.GET_SLIP_PDF,
    promise: getSlipPDF(request),
  }
}

export const getSlipPDFResetAction = () => ({
  type: `${ActionTypes.GET_SLIP_PDF}_RESET`,
})

/*===================Slip pdf==============================*/

export const getEntriesByDateAction = (startDate: string, endDate: string) => {
  return {
    type: ActionTypes.GET_ENTRIES_BY_DATE,
    promise: getEntriesByDateAction(startDate, endDate),
  }
}

export const getEntriesByDateResetAction = () => ({
  type: `${ActionTypes.GET_ENTRIES_BY_DATE}_RESET`,
})

/*===================bill till today pdf==============================*/

export const getBillTillTodayPDFAction = (request: BillPDFRequest) => {
  return {
    type: ActionTypes.GET_TODAY_BILL_PDF,
    promise: getTodayBill(request),
  }
}

export const getBillTillTodayPDFResetAction = () => ({
  type: `${ActionTypes.GET_TODAY_BILL_PDF}_RESET`,
})

/*===================Add Item==============================*/

export const addStockItemAction = (request: AddStockItemRequest) => {
  return {
    type: ActionTypes.ADD_STOCK_ITEM,
    promise: addStockItem(request),
  }
}

export const addStockItemResetAction = () => ({
  type: `${ActionTypes.ADD_STOCK_ITEM}_RESET`,
})

/*===================Pending Items==============================*/

export const fetchPendingItemsAction = (itemId: string) => ({
  type: ActionTypes.FTECH_PENDING_ITEMS,
  promise: getPendingItems(itemId),
})

// Search fucntion
export const searchItem = (searchTerm, data) => {
  let searchResults = []
  if (data?.length) {
    searchResults = data.filter((item) => {
      const { itemName } = item
      if (itemName?.toLowerCase().includes(searchTerm?.toLowerCase())) {
        return true
      } else {
        return false
      }
    })
  }
  return searchResults
}

export const searchItemAction = (searchTerm) => {
  return (dispatch, getState) => {
    const { list } = getState().manageItems
    const { data } = list
    const searchResults = searchItem(searchTerm, data)
    dispatch({
      type: ActionTypes.UPDATE_SEARCH_ITEM_RESULTS,
      res: { data: searchResults, searchTerm },
    })
  }
}
export const deleteItemAction = (id: number) => ({
  type: ActionTypes.DELETE_STOCK_ITEM,
  promise: deleteStockItem(id),
})

export const deleteItemResetAction = () => ({
  type: `${ActionTypes.DELETE_STOCK_ITEM}_RESET`,
})

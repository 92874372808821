import { FC, Fragment } from 'react'
import { List, Grid, Card, CardContent, Divider } from '@mui/material'
import { ItemEntryDetail } from '../../../services/slips'
import { TabsEnums } from '../../../components'
import { LeftListItemComponent } from './LeftListItemComponent'

interface LeftItemComponentProps {
  data: ItemEntryDetail[]
  separatorHeight: number
  showOnlyBalanceItems: boolean
}

export const LeftItemComponent: FC<LeftItemComponentProps> = ({
  data,
  separatorHeight,
  showOnlyBalanceItems
}) => {
  return (
    <>
      {data && data.length > 0 && (
        <>
          <Divider />
          <Grid
            id="properties_name"
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              minHeight: '4vh',
              pl: 2,
              pr: 2,
              backgroundColor: '#f9f9f9',
              fontWeight: 'bold',
            }}
          >
            <Grid xs={2.75} sx={{ pl: 4 }}>
              Item Name
            </Grid>
            <Grid xs={2.75}>Total Issued</Grid>
            <Grid xs={2.75}>Total Received</Grid>
            <Grid xs={2.75}>Status</Grid>
            <Grid xs={1} sx={{ paddingLeft: 4 }}>
              Balance
            </Grid>
          </Grid>
          {data &&
            data.map((items, index) => {
              return (
                <List
                  id="entries_list_items_per_date"
                  sx={{
                    width: '100%',
                    p: 0,
                    m: 0,
                  }}
                  component="div"
                  aria-labelledby="subheader"
                  key={index}
                >
                  {items.itemEntries &&
                    items.itemEntries.map((item, i) => {
                    if(!showOnlyBalanceItems || item.quantity > 0) {
                      return (
                        <Fragment key={i}>
                          <Divider />
                          <LeftListItemComponent
                            item={item}
                            key={i}
                            componentName={TabsEnums.Left}
                            bgcolor={item.id < 0 ? '#e0e0e0' : 'white'}
                          />
                        </Fragment>
                      )
                    }
                    })}
                </List>
              )
            })}
        </>
      )}
    </>
  )
}

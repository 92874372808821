import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import {
  Response,
  Customer,
  EntryType,
  TimeTypeEnum,
  Item,
  ItemCategory,
} from '../types'
import { Store } from '../user'
import { EwayItem } from 'src/content/applications/slips/eway/Services'

export type AddStockItemRequest = {
  id?: number
  area: number
  availableQuantity: number
  isActive: boolean
  itemName: string
  length: number
  width: number
  rate: number
  size: string
  category: any
  issuedQuantity: number
  isPlate: boolean
  totalQuantity: number
  runningLength: number
  hsnCode?: string
  weight?: number
  value?: number
}

export type IssueItem = Pick<Item, 'itemName' | 'rate' | 'id'>
// type WithPartial<T, K extends keyof T> = T & { [P in K]?: T[P] }

export type PrepareIssueItem = {
  item: {
    id: number
    label: string
    category?: string
  }
  entryType: EntryType
  quantity?: number
  rate?: number
  timeType?: string
  lostItemCost?: number
  minRentalDays?: number
  height?: number
  width?: number
  length?: number
  outAreaEntryId?: number
  areaOutEntryId?: number
  isArea?: boolean
  siteTransferRate?: number
}

export type IssueItemRequest = {
  items: PrepareIssueItem[]
  quotationItems?: PrepareIssueItem[]
  customerId: number
  date_entry: string
  site_address?: string
  bill_type?: string
  time?: string
  deposite?: number
  ceiling_area?: string
  rate_per_sqft?: string
  bill_id?: number
  billStatus?: string
  vehicleNo?: string
  challanNo?: string
  challanId?: string
  driverName?: string
  fromSiteId?: number
  fromCustomerId?: number
}

export type CategoryItemsResponse = {
  items: Item[]
  category: ItemCategory
}

export interface FetchItemResponse extends Response {
  data: CategoryItemsResponse
}

export function fetchItemList(categoryId?: number) {
  let url = '/item/pvt/v2'
  if (categoryId) {
    url = `/item/pvt/v2?category_id=${categoryId}`
  }
  return http.get(url).then((response: AxiosResponse) => response)
}

/**
 * This API call is to populate the select item dropdown while issuing the item
 * @returns
 */
export function fetchSelectItemOptions(
  customer_id: number,
  agreementId?: number,
  siteId?: number
) {
  let url = `/item/pvt/v2/stock/items?customer_id=${customer_id}`
  if (agreementId) {
    url = `/item/pvt/v2/stock/items?customer_id=${customer_id}&agreement_id=${agreementId}`
  }
  if (siteId) {
    url = `${url}&from_site=${siteId}`
  }
  return http.get(url).then((response: AxiosResponse) => response)
}

export interface Bill {
  id: number
  discount: number
  daysDiscount: number
  total: number
  subtotal: number
  lostItemsCost: number
  otherCharges: number
  status: string
  dailyBillAmount: number
  totalPaid: number
  totalRent: number
  totalRentWithoutConsumable: number
  consumableTotal: number
  wallsAreaRent: number
  sellItemsTotalCost: number
  totalCeilingArea: number
  totalPlateArea: number
  wallAreaPercentage: number
  totalDue: number
  outstandingTillLastEntry: number
  customer: Customer
  openDate: string
  createDate: string
  closeDate: string
  ratePerSqft: number
  billAgeInDays: number
  siteAddress: string
  billType: string
  isSqftFlatRate: boolean
  sqftStartDate: string
  sqftEndDate: string
  updateDate: string
  isGSTEnabled: boolean
  isRecurringEnabled: boolean
  agreementTitle?: string
  agreementId?: number
  state?: string
  stateCode?: string
  gstType?: string
  sitePlace?: string
  sitePincode?: string
  is30DaysMonthEnabled?: boolean
  remainingPieces?: number
  overDaysRate?: number
  sqftMinDays?: number
  cgstRate?: number
  sgstRate?: number
  igstRate?: number
  openingBalance?: number
}

export type AccountRequest = {
  date?: string
  customerId: number
  billId?: number
  siteAddress?: string
  billType: string
  ratePerSqft: number
  ceilingArea: number
  wallPercent: number
  isSqftFlatRate: boolean
  isGSTEnabled: boolean
  enteries: any
  customerName?: string
  customerMobile?: string
  customerAddress?: string
  billStatus?: string
  customerGSTIN?: string
  discount?: number
  isRecurringEnabled: boolean
  agreementTitle?: string
  agreementId?: number
  deposit?: number
  quotationEntries?: any
  state?: string
  stateCode?: string
  gstType?: string
  sitePlace?: string
  sitePincode?: number
  is30DaysMonthEnabled?: boolean
  overDaysRate?: number
  sqftMinDays?: number
  openingBalance?: number

}

export interface IssueItemResponse extends Response {
  data?: Bill
}

// Issue Item Request
export function issueItem(request: IssueItemRequest) {
  const totalPaid = request.deposite ? request.deposite : 0

  const accountRequest = {
    enteries: request.items,
    quotationEntries: request.quotationItems,
    customerId: request.customerId,
    date: request.date_entry,
    siteAddress: request.site_address,
    billType: request.bill_type,
    deposit: totalPaid,
    ceilingArea: request.ceiling_area,
    ratePerSqft: request.rate_per_sqft,
    billId: request.bill_id,
    billStatus: request.billStatus,
    vehicleNo: request.vehicleNo,
    challanNo: request.challanNo,
    challanId: request.challanId,
    driverName: request.driverName,
    fromSiteId: request.fromSiteId,
    fromCustomerId: request.fromCustomerId
  }
  return http
    .post('/bill/pvt/v2', accountRequest)
    .then((response: AxiosResponse) => response)
}

// Update Issued Item
export type EditItemRequest = {
  id: number
  entryDate?: string
  quantity?: number
  rate?: number
  timeType?: TimeTypeEnum
  receivedAmount?: number
  lostItemCost?: number
  isQuotation?: boolean
  challanNo?: string
  challanId?: string
}

export type SlipPDFRequest = {
  billId: number
  showRate: boolean
  slipType: string
  title?: string
  isQuotation?: boolean
  isRojnamcha?: boolean
  from?: string
  to?: string
  showGoodsValue?: boolean
  hideTotalEntries?: boolean
  challanId?: number
}

export type EwayBillRequest = {
  accountId: number
  challanId: number
  challanNumber: string
  entryType: string
  transactionType: number
  distance: number
  vehicleNo: string
  siteAddress1: string
  siteAddress2: string
  sitePlace: string
  sitePincode: string
  siteState: string
  siteStateCode: string
  store: Store
  customer: Customer
  transporterId?: string
  transporterName?: string
  transDocNo?: string
  transDocDate?: string
  itemList: EwayItem[]
  totalValue: number // Total value of all items including tax
  cgstValue: number // Total CGST value
  sgstValue: number // Total SGST value
  igstValue: number // Total IGST value
  totInvoiceValue: number // Total invoice value
}

export type CancelEwayBillRequest = {
  ewbNo: number
  cancelRsnCode: number
  cancelRmrk?: string
  challanId: number
}

export type UpdatePartEWayBillRequest = {
  ewbNo: number
  vehicleNo: string
  fromPlace: string
  fromState: number
  reasonCode: string
  reasonRem?: string
  transDocNo?: string
  transDocDate?: string
  transMode: string
  vehicleType: string
}

export type AddPaymentRequest = {
  billId: number
  paymentId?: number
  paymentDate?: string
  amount?: number
  note?: string
  refNo?: string
}

export type AddPaymentResponse = {
  id: number
  amount: number
  note?: string
  paymentDate: string
}

export interface AddPaymentAPIResponse extends Response {
  data: AddPaymentResponse
}

export interface DeletePaymentAPIResponse extends Response {
  data: boolean
}

export interface SlipPDFAPIResponse extends Response {
  data: string
}

export interface AddStockItemAPIResponse extends Response {
  data: null
}

export interface DeleteItemAPIResponse extends Response {
  data: Item | null
}
export interface DeleteChallanAPIResponse extends Response {
  data: boolean | null
}

export type UpdateIssuedItemResponse = {
  id: number
  paymentId: number
  minRentalDays: number
  receivedAmount: number
  quantity: number
  lostItemCost: number
  entryDate: string
  timeType: TimeTypeEnum
  rate: number
}
export interface UpdateIssuedItemAPIResponse extends Response {
  data: UpdateIssuedItemResponse
}

export interface PendingItemEntry {
  itemName: string
  quantity: number
  issueDate: string
}

export interface PendingItemsData {
  name
  customerMobile: string
  billEntries: [PendingItemEntry]
}
export interface FetchPendingItemsAPIResponse extends Response {
  data: PendingItemsData[]
}

export type EWayBillData = {
  challanId: number
  ewayBillLink: string
  ewayBillNo: string
}

export interface CreateEWayBillAPIResponse extends Response {
  data: EWayBillData
}

export type GSTINDetailsResponse = {
  gstin: string
  tradeName: string
  legalName: string
  address1: string
  address2: string
  stateCode: string
  pinCode: string
  txpType: string
  status: string
  blkStatus: string
}

export interface GSTINDetailsAPIResponse extends Response {
  data: GSTINDetailsResponse
}

// Issue Item Request
export function updateIssuedItem(request: EditItemRequest) {
  return http
    .put('/entry/pvt/', request)
    .then((response: AxiosResponse) => response)
}

export function deleteChallan(id: number) {
  return http
    .delete(`/challan/pvt?challan_id=${id}`)
    .then((response: AxiosResponse) => response)
}

// Add payment Request
export function addPayment(request: AddPaymentRequest) {
  return http
    .post('/payment/pvt/', request)
    .then((response: AxiosResponse) => response)
}

// Delete payment Request
export function deletePayment(id: number) {
  return http
    .delete(`payment/pvt/delete?payment_id=${id}`)
    .then((response: AxiosResponse) => response)
}

// Slip pdf
export function getSlipPDF(request: SlipPDFRequest) {
  return http
    .post('/bill/pvt/slip_pdf', request)
    .then((response: AxiosResponse) => response)
}

export function getEwayBill(eWayBillNumber: number) {
  return http
    .get(`/eway_bill/pvt?eway_bill_no=${eWayBillNumber}`)
    .then((response: AxiosResponse) => response)
}

export function createEwayBill(request: EwayBillRequest) {
  return http
    .post(`${process.env.EWAY_API_BASE_URL}ewaybill`, request)
    .then((response: AxiosResponse) => response)
}

export function cancelEwayBill(request: CancelEwayBillRequest) {
  return http
    .post(`${process.env.EWAY_API_BASE_URL}ewaybill/cancel`, request)
    .then((response: AxiosResponse) => response)
}

export function updatePartBEwayBill(request: UpdatePartEWayBillRequest) {
  return http
    .post(`${process.env.EWAY_API_BASE_URL}ewaybill/update_part_b`, request)
    .then((response: AxiosResponse) => response)
}

export function viewEwayBill(eWayBillNumber: number) {
  return http
    .get(
      `${process.env.EWAY_API_BASE_URL}ewaybill/view_pdf?eWayNo=${eWayBillNumber}`
    )
    .then((response: AxiosResponse) => response)
}

export function getGSTNDetails(gstin: string) {
  return http
    .get(`${process.env.EWAY_API_BASE_URL}gst/gstin?gstin=${gstin}`)
    .then((response: AxiosResponse) => response)
}

export function getEntriesByDate(startDate: string, endDate: string) {
  return http
    .get(`/entry/pvt/entries_bw_dates?from=${startDate}&to=${endDate}`)
    .then((response: AxiosResponse) => response)
}

// Slip pdf
export function addStockItem(request: AddStockItemRequest) {
  return http
    .post('/item/pvt', request)
    .then((response: AxiosResponse) => response)
}

// Slip pdf
export function deleteStockItem(id: number) {
  return http
    .delete(`/item/pvt/${id}`)
    .then((response: AxiosResponse) => response)
}

// Pending items
export function getPendingItems(itemId: string) {
  return http
    .get(`/entry/pvt/pending_items/${itemId}`)
    .then((response: AxiosResponse) => response)
}
